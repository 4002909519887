// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.muutoshakemus-form-section {
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
  background-color: #fefefe;
  margin: 2rem 0;
}
.muutoshakemus-form-section_date-picker-fix {
  position: relative;
  z-index: 1;
}
.muutoshakemus-form-section_content {
  padding: 0 1.5rem 0 1rem;
}
.muutoshakemus-form-section_cta {
  background: #F7FCFF;
  mix-blend-mode: darken;
  border: 1px solid #D6F1FF;
  box-sizing: border-box;
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./soresu-form/web/va/MuutoshakemusSection.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,sBAAA;EACA,oDAAA;EACA,yBAAA;EACA,cAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;AACJ;AAGA;EACE,wBAAA;AADF;AAIA;EACE,mBAAA;EACA,sBAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;AAFF","sourcesContent":[".muutoshakemus-form-section {\n  border: 1px solid #EAEAEA;\n  box-sizing: border-box;\n  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));\n  background-color: #fefefe;\n  margin: 2rem 0;\n\n  &_date-picker-fix {\n    position: relative;\n    z-index: 1;\n  }\n}\n\n.muutoshakemus-form-section_content {\n  padding: 0 1.5rem 0 1rem;\n}\n\n.muutoshakemus-form-section_cta {\n  background: #F7FCFF;\n  mix-blend-mode: darken;\n  border: 1px solid #D6F1FF;\n  box-sizing: border-box;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
