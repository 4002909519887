// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.muutoshakemus-osio-paatos {
  border: 1px solid;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  padding: 1rem;
  align-items: center;
  background: #F5F5F5;
  border-color: #EAEAEA;
}
.muutoshakemus-osio-paatos > svg {
  padding-right: 1rem;
  padding-top: 1px;
}
`, "",{"version":3,"sources":["webpack://./soresu-form/web/va/OsioPaatos.less"],"names":[],"mappings":"AAAA;EAEE,iBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EAEA,mBAAA;EACA,qBAAA;AADF;AATA;EAaI,mBAAA;EACA,gBAAA;AADJ","sourcesContent":[".muutoshakemus-osio-paatos {\n\n  border: 1px solid;\n  box-sizing: border-box;\n  display: flex;\n  flex-grow: 1;\n  padding: 1rem;\n  align-items: center;\n\n  background: #F5F5F5;\n  border-color: #EAEAEA;\n\n  > svg {\n    padding-right: 1rem;\n    padding-top: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
